#site-footer{
	position: relative;
	top: 100vh;
	left: 0;
	z-index: 2;
	background: $normal;
	color: #FFF;
	#pageup{
		background: rgba(#FFF,.12);
		color: rgba(#FFF,.72);
		padding: 20px 0;
		display: block;
		text-align: center;
		@media screen and (max-width:$responsive){
			padding: 15px;	
		}
	}
	.meta{
		padding: 64px 0;
		@include flex();
		font-size: 15px;
		@media screen and (max-width:$responsive){
			padding: 32px 15px;	
			display: block;
			font-size: 12px;
			text-align: center;
		}
		.sns{
			@media screen and (max-width:$responsive){
				text-align: center;
				margin: 24px 0 0;
			}
			li{
				display: inline-block;
				margin: 0 24px 0 0;
				font-size: 18px;
				@media screen and (max-width:$responsive){
					font-size: 16px;	
				}
				&:last-child{
					margin: 0;
				}
				i{
					opacity: .64;
					color: #FFF;
					&:hover{
						opacity: 1;
					}
				}
			}
		}
	}
}
.site-recruit{
	.list{
		> li{
			@media screen and (min-width:$responsive + 1px){
				@include clum(2,48.5%,3%);
			}
			padding: 20px;
			background: #FFF;
			position: relative;
			cursor: pointer;
			border: $border 1px solid;
			box-shadow: 0 0 8px rgba(#000,.08);
			@include flex();
			@include transition(80ms);
			@media screen and (max-width:$responsive){
				padding: 15px;
				margin: 0 0 15px;
				&:last-child{
					margin: 0;
				}
			}
			&:hover{
				transform: scale(1.04);
				z-index: 2;
				box-shadow: 0 0 64px rgba(#000,.16);
				@media screen and (max-width:$responsive){
					transform: scale(1);
				}
			}
			.bg{
				width: 76px;
				height: 76px;
				background-size: cover;
				background-position: center;
				@media screen and (max-width:$responsive){
					width: 48px;
					height: 48px;
				}
			}
			dl{
				width: calc(100% - 100px);
				@include flex();
				@media screen and (max-width:$responsive){
					width: calc(100% - 64px);
				}
				dt,dd{
					width: 100%;
				}
				dt{
					font-size: 20px;
					font-weight: 700;
					@media screen and (max-width:$responsive){
						font-size: 16px;
						padding: 0;
						margin: -3px 0 0;
					}
				}
				dd{
					font-size: 13px;
					@media screen and (max-width:$responsive){
						font-size: 11px;	
					}
					&.border{
						border-bottom: $normal 1px solid;
						padding: 0 0 16px;
						margin: 0 0 16px;
						width: 100%;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						@media screen and (max-width:$responsive){
							padding: 0;
							margin: 0;
							border: none;
						}
					}
				}
			}
			.meta{
				width: 100%;
				@include flex();
				@media screen and (max-width:$responsive){
					padding: 16px 0 0;
					display: block;
				}
				li{
                    width: 48%;
                    font-size: 16px;
                    font-weight: 700;
					@media screen and (max-width:$responsive){
						font-size: 14px;
						width: 100%;
						margin: 0 0 8px;
						&:last-child{
							margin: 0;
						}
					}
                    &:before{
                        font-weight: 500;
                        background: $normal;
                        color: #FFF;
                        padding: 1px 0;
                        font-size: 12px;
                        width: 80px;
                        margin: 0 16px 0 0;
                        transform: translateY(-1px);
                        text-align: center;
                        display: inline-block;
                        content: attr(data-text);
						@media screen and (max-width:$responsive){
							font-size: 11px;
							width: 64px;
							margin: 0 12px 0 0;
						}
                    }
				}
			}
			i{
				position: absolute;
				top: 20px;
				right: 20px;
				border: $normal 1px solid;
				padding: 0 12px;
				color: $normal;
				font-size: 12px;
				@media screen and (max-width:$responsive){
					top: 15px;
					right: 15px;
					font-size: 10px;
				}
			}
		}
	}	
}


.recruit-component{
	.mes{
		font-weight: 700;
		margin: 0 0 32px;
		dt{
			font-size: 40px;
			@media screen and (max-width:$responsive){
				font-size: 24px;	
			}
		}
		dd{
			font-size: 20px;
			@media screen and (max-width:$responsive){
				font-size: 13px;
				font-weight: 500;
				margin: 4px 0 0;
			}
		}
	}
	.tag{
		margin: 0 0 52px;
		@media screen and (max-width:$responsive){
			margin: 0 0 24px;	
		}
		li{
			background: #f2f2f2;
			padding: 4px 12px;
			display: inline-block;
			margin: 0 12px 12px 0;
			border-radius: 4px;
			@media screen and (max-width:$responsive){
				padding: 3px 10px;
				margin: 0 8px 8px 0;
			}
		}
	}
	.text{
		font-size: 15px;
		margin: 0 0 80px;
		@media screen and (max-width:$responsive){
			font-size: 14px;
			margin: 0 0 32px;
		}
	}
	.mainphoto{
		width: 400px;
		height: 250px;
        background-position: center;
        background-size: cover;
		margin: 0 0 80px 32px;
		float: right;
		@media screen and (max-width:$responsive){
			width: 100%;
			height: 200px;
			margin: 0 0 15px;
			float: none;
		}
	}
	.photos{
		margin: 64px 0 0;
		@include flex();
		@media screen and (max-width:$responsive){
			margin: 32px 0 0;
			display: block;
		}
		li{
			width: 31%;
			list-style: none;
			@media screen and (max-width:$responsive){
				width: auto;
				margin: 0 0 24px;
				&:last-child{
					margin: 0;
				}
			}
			.bg{
				height: 200px;
				margin: 0 0 16px;
				background-position: center;
				background-size: cover;
			}
			.caption{
				font-size: 13px;
			}
		}
	}
}
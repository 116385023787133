@charset "UTF-8";
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #000;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: 'Lato',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: 1024px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #000;
    background: #FFF;
    font-size: 12px;
    line-height: 1.8;
    font-family: 'Lato',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#site-header {
  width: 100%;
  height: 80px;
  background: #FFF;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0 40px;
  padding-right: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-transition: all 400ms 400ms ease-in-out;
  -moz-transition: all 400ms 400ms ease-in-out;
  -ms-transition: all 400ms 400ms ease-in-out;
  -o-transition: all 400ms 400ms ease-in-out;
  transition: all 400ms 400ms ease-in-out;
  align-items: center; }
  @media screen and (max-width: 768px) {
    #site-header {
      height: 60px;
      padding: 0 0 0 15px; } }
  #site-header.hidden {
    top: -80px;
    -webkit-transition: all 400ms 0ms ease-in-out;
    -moz-transition: all 400ms 0ms ease-in-out;
    -ms-transition: all 400ms 0ms ease-in-out;
    -o-transition: all 400ms 0ms ease-in-out;
    transition: all 400ms 0ms ease-in-out; }
  #site-header .logo a {
    float: left; }
    #site-header .logo a img {
      height: 30px; }
      @media screen and (max-width: 768px) {
        #site-header .logo a img {
          height: 22px; } }
  #site-header .logo h1 {
    float: left;
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap;
    margin: 8px 0 0 24px; }
    @media screen and (max-width: 768px) {
      #site-header .logo h1 {
        font-size: 10px;
        margin: 6px 0 0 12px; } }
  #site-header .gnavi__list {
    float: left;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    #site-header .gnavi__list > li {
      list-style: none;
      float: left;
      margin: 0 48px 0 0; }
      @media screen and (max-width: 768px) {
        #site-header .gnavi__list > li {
          display: none; } }
      #site-header .gnavi__list > li:last-child {
        margin: 0; }
      #site-header .gnavi__list > li.contact {
        margin: 0 0 0 8px; }
        @media screen and (max-width: 1200px) {
          #site-header .gnavi__list > li.contact {
            margin: 0; } }
        @media screen and (max-width: 768px) {
          #site-header .gnavi__list > li.contact {
            display: block; } }
        #site-header .gnavi__list > li.contact a {
          display: block;
          text-align: center;
          height: 80px;
          background: #000;
          color: #FFF;
          font-weight: 500;
          font-size: 14px;
          padding: 0 32px;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
          @media screen and (max-width: 768px) {
            #site-header .gnavi__list > li.contact a {
              height: 60px;
              padding: 0 16px;
              font-size: 9px; } }
          #site-header .gnavi__list > li.contact a:hover {
            color: #FFF;
            background: #292929; }
          #site-header .gnavi__list > li.contact a i {
            display: block;
            padding: 16px 0 6px;
            font-size: 18px; }
            @media screen and (max-width: 768px) {
              #site-header .gnavi__list > li.contact a i {
                font-size: 14px;
                padding-top: 14px; } }
      @media screen and (max-width: 1200px) {
        #site-header .gnavi__list > li.social-link {
          display: none; } }
      @media screen and (max-width: 1200px) {
        #site-header .gnavi__list > li .sns {
          display: none; } }
      #site-header .gnavi__list > li .sns li {
        float: left;
        margin: 0 32px 0 0; }
        #site-header .gnavi__list > li .sns li:last-child {
          margin: 0; }
      #site-header .gnavi__list > li a {
        font-size: 15px;
        color: #000;
        font-weight: 700; }
        #site-header .gnavi__list > li a:hover {
          color: #666666; }

#site-footer {
  position: relative;
  top: 100vh;
  left: 0;
  z-index: 2;
  background: #000;
  color: #FFF; }
  #site-footer #pageup {
    background: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.72);
    padding: 20px 0;
    display: block;
    text-align: center; }
    @media screen and (max-width: 768px) {
      #site-footer #pageup {
        padding: 15px; } }
  #site-footer .meta {
    padding: 64px 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    font-size: 15px; }
    @media screen and (max-width: 768px) {
      #site-footer .meta {
        padding: 32px 15px;
        display: block;
        font-size: 12px;
        text-align: center; } }
    @media screen and (max-width: 768px) {
      #site-footer .meta .sns {
        text-align: center;
        margin: 24px 0 0; } }
    #site-footer .meta .sns li {
      display: inline-block;
      margin: 0 24px 0 0;
      font-size: 18px; }
      @media screen and (max-width: 768px) {
        #site-footer .meta .sns li {
          font-size: 16px; } }
      #site-footer .meta .sns li:last-child {
        margin: 0; }
      #site-footer .meta .sns li i {
        opacity: .64;
        color: #FFF; }
        #site-footer .meta .sns li i:hover {
          opacity: 1; }

#firstview {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  background: #000;
  z-index: 1;
  align-items: center; }
  @media screen and (max-width: 768px) {
    #firstview {
      top: 60px;
      height: calc(100% - 60px); } }
  #firstview .concept {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.04);
    padding-left: 8%;
    color: #FFF;
    text-shadow: 0 0 32px rgba(0, 0, 0, 0.64);
    z-index: 2;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    font-weight: 900; }
    #firstview .concept dl {
      transform: translateY(-40px); }
      #firstview .concept dl dt {
        font-size: calc(48px + 32 * (100vw - 450px)/ 900);
        text-shadow: 0 0 32px rgba(0, 0, 0, 0.16);
        line-height: 1.3; }
      #firstview .concept dl dd {
        font-size: 24px; }
        @media screen and (max-width: 768px) {
          #firstview .concept dl dd {
            font-size: 18px; } }
  #firstview .swiper-slide {
    height: calc(100vh - 80px); }
    @media screen and (max-width: 768px) {
      #firstview .swiper-slide {
        height: calc(100vh - 60px); } }
  #firstview .swiper-slide-active .swipe-bg,
  #firstview .swiper-slide-duplicate-active .swipe-bg,
  #firstview .swiper-slide-prev .swipe-bg {
    animation: zoomUp 10s linear 0s 1 normal both; }

@keyframes zoomUp {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.16); } }
  #firstview .swipe-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%; }
  #firstview .scroll {
    position: absolute;
    right: 40px;
    bottom: 0;
    z-index: 2;
    width: 32px;
    height: 25vh; }
    @media screen and (max-width: 768px) {
      #firstview .scroll {
        right: 15px; } }
    #firstview .scroll i {
      position: absolute;
      left: 0;
      top: 0;
      text-align: right;
      white-space: nowrap;
      color: #FFF;
      font-size: 11px;
      line-height: 1;
      font-weight: 500;
      letter-spacing: .32em;
      -webkit-writing-mode: vertical-rl;
      -moz-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl; }
    #firstview .scroll:before, #firstview .scroll:after {
      content: '';
      width: 1px;
      height: 25vh;
      background: #FFF;
      position: absolute;
      top: 0;
      right: 0; }
    #firstview .scroll:after {
      background: #000;
      animation: sc 3s infinite; }

@keyframes sc {
  0% {
    height: 0; }
  30% {
    height: 25vh; }
  61% {
    top: 0;
    height: 25vh; }
  90% {
    top: 25vh;
    height: 0; }
  100% {
    top: 25vh;
    height: 0; } }

.news-block {
  background: #000;
  color: #FFF;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 40%;
  font-size: 15px;
  z-index: 2;
  padding: 30px 40px;
  overflow: hidden;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .news-block {
      width: calc(100% - 88px);
      padding: 18px 15px;
      font-size: 12px; } }
  .news-block .timeline {
    line-height: 1;
    width: calc(100% - 64px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    @media screen and (max-width: 768px) {
      .news-block .timeline {
        width: calc(100% - 48px); } }
    .news-block .timeline time {
      display: inline-block;
      margin: 0 20px 0 0;
      padding: 0 20px 0 0;
      border-right: rgba(255, 255, 255, 0.24) 1px solid; }
  .news-block .more {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 16px;
    transform: translateY(-50%);
    -webkit-transition: all 200ms 0ms ease-in-out;
    -moz-transition: all 200ms 0ms ease-in-out;
    -ms-transition: all 200ms 0ms ease-in-out;
    -o-transition: all 200ms 0ms ease-in-out;
    transition: all 200ms 0ms ease-in-out; }
    @media screen and (max-width: 768px) {
      .news-block .more {
        right: 15px; } }

.news-component ol li {
  list-style: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 32px;
  padding: 0 0 32px;
  border-bottom: #e5e5e5 1px solid; }
  @media screen and (max-width: 768px) {
    .news-component ol li {
      display: block;
      margin: 0 0 15px;
      padding: 0 0 15px; } }
  .news-component ol li time {
    width: 15%;
    background: #000;
    color: #FFF;
    padding: 2px 0;
    text-align: center;
    display: block;
    font-size: 12px; }
    @media screen and (max-width: 768px) {
      .news-component ol li time {
        width: 88px; } }
  .news-component ol li dl {
    width: 85%;
    padding: 0 0 0 40px; }
    @media screen and (max-width: 768px) {
      .news-component ol li dl {
        padding: 15px 0 0;
        width: auto; } }
    .news-component ol li dl dt {
      font-weight: 700;
      font-size: 17px;
      line-height: 1.5;
      margin: 0 0 8px; }
      @media screen and (max-width: 768px) {
        .news-component ol li dl dt {
          font-size: 15px; } }
    .news-component ol li dl dd {
      font-size: 13px;
      text-align: justify; }
      @media screen and (max-width: 768px) {
        .news-component ol li dl dd {
          font-size: 12px; } }

.site-recruit .list > li {
  padding: 20px;
  background: #FFF;
  position: relative;
  cursor: pointer;
  border: #e5e5e5 1px solid;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-transition: all 80ms 0ms ease-in-out;
  -moz-transition: all 80ms 0ms ease-in-out;
  -ms-transition: all 80ms 0ms ease-in-out;
  -o-transition: all 80ms 0ms ease-in-out;
  transition: all 80ms 0ms ease-in-out; }
  @media screen and (min-width: 769px) {
    .site-recruit .list > li {
      list-style: none;
      width: 48.5%;
      float: left;
      margin: 0 3% 3% 0; }
      .site-recruit .list > li:nth-child(2n+1) {
        clear: both; }
      .site-recruit .list > li:nth-child(2n+2) {
        margin-right: 0; }
      .site-recruit .list > li:nth-last-child(-n+2) {
        margin-bottom: 0; } }
  @media screen and (max-width: 768px) {
    .site-recruit .list > li {
      padding: 15px;
      margin: 0 0 15px; }
      .site-recruit .list > li:last-child {
        margin: 0; } }
  .site-recruit .list > li:hover {
    transform: scale(1.04);
    z-index: 2;
    box-shadow: 0 0 64px rgba(0, 0, 0, 0.16); }
    @media screen and (max-width: 768px) {
      .site-recruit .list > li:hover {
        transform: scale(1); } }
  .site-recruit .list > li .bg {
    width: 76px;
    height: 76px;
    background-size: cover;
    background-position: center; }
    @media screen and (max-width: 768px) {
      .site-recruit .list > li .bg {
        width: 48px;
        height: 48px; } }
  .site-recruit .list > li dl {
    width: calc(100% - 100px);
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-recruit .list > li dl {
        width: calc(100% - 64px); } }
    .site-recruit .list > li dl dt, .site-recruit .list > li dl dd {
      width: 100%; }
    .site-recruit .list > li dl dt {
      font-size: 20px;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .site-recruit .list > li dl dt {
          font-size: 16px;
          padding: 0;
          margin: -3px 0 0; } }
    .site-recruit .list > li dl dd {
      font-size: 13px; }
      @media screen and (max-width: 768px) {
        .site-recruit .list > li dl dd {
          font-size: 11px; } }
      .site-recruit .list > li dl dd.border {
        border-bottom: #000 1px solid;
        padding: 0 0 16px;
        margin: 0 0 16px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
        @media screen and (max-width: 768px) {
          .site-recruit .list > li dl dd.border {
            padding: 0;
            margin: 0;
            border: none; } }
  .site-recruit .list > li .meta {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-recruit .list > li .meta {
        padding: 16px 0 0;
        display: block; } }
    .site-recruit .list > li .meta li {
      width: 48%;
      font-size: 16px;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .site-recruit .list > li .meta li {
          font-size: 14px;
          width: 100%;
          margin: 0 0 8px; }
          .site-recruit .list > li .meta li:last-child {
            margin: 0; } }
      .site-recruit .list > li .meta li:before {
        font-weight: 500;
        background: #000;
        color: #FFF;
        padding: 1px 0;
        font-size: 12px;
        width: 80px;
        margin: 0 16px 0 0;
        transform: translateY(-1px);
        text-align: center;
        display: inline-block;
        content: attr(data-text); }
        @media screen and (max-width: 768px) {
          .site-recruit .list > li .meta li:before {
            font-size: 11px;
            width: 64px;
            margin: 0 12px 0 0; } }
  .site-recruit .list > li i {
    position: absolute;
    top: 20px;
    right: 20px;
    border: #000 1px solid;
    padding: 0 12px;
    color: #000;
    font-size: 12px; }
    @media screen and (max-width: 768px) {
      .site-recruit .list > li i {
        top: 15px;
        right: 15px;
        font-size: 10px; } }

.recruit-component .mes {
  font-weight: 700;
  margin: 0 0 32px; }
  .recruit-component .mes dt {
    font-size: 40px; }
    @media screen and (max-width: 768px) {
      .recruit-component .mes dt {
        font-size: 24px; } }
  .recruit-component .mes dd {
    font-size: 20px; }
    @media screen and (max-width: 768px) {
      .recruit-component .mes dd {
        font-size: 13px;
        font-weight: 500;
        margin: 4px 0 0; } }

.recruit-component .tag {
  margin: 0 0 52px; }
  @media screen and (max-width: 768px) {
    .recruit-component .tag {
      margin: 0 0 24px; } }
  .recruit-component .tag li {
    background: #f2f2f2;
    padding: 4px 12px;
    display: inline-block;
    margin: 0 12px 12px 0;
    border-radius: 4px; }
    @media screen and (max-width: 768px) {
      .recruit-component .tag li {
        padding: 3px 10px;
        margin: 0 8px 8px 0; } }

.recruit-component .text {
  font-size: 15px;
  margin: 0 0 80px; }
  @media screen and (max-width: 768px) {
    .recruit-component .text {
      font-size: 14px;
      margin: 0 0 32px; } }

.recruit-component .mainphoto {
  width: 400px;
  height: 250px;
  background-position: center;
  background-size: cover;
  margin: 0 0 80px 32px;
  float: right; }
  @media screen and (max-width: 768px) {
    .recruit-component .mainphoto {
      width: 100%;
      height: 200px;
      margin: 0 0 15px;
      float: none; } }

.recruit-component .photos {
  margin: 64px 0 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .recruit-component .photos {
      margin: 32px 0 0;
      display: block; } }
  .recruit-component .photos li {
    width: 31%;
    list-style: none; }
    @media screen and (max-width: 768px) {
      .recruit-component .photos li {
        width: auto;
        margin: 0 0 24px; }
        .recruit-component .photos li:last-child {
          margin: 0; } }
    .recruit-component .photos li .bg {
      height: 200px;
      margin: 0 0 16px;
      background-position: center;
      background-size: cover; }
    .recruit-component .photos li .caption {
      font-size: 13px; }

.site-works .listable {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-works .listable {
      display: block; } }
  .site-works .listable li {
    width: 48%;
    margin: 48px 0 0;
    background: #FFF;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    -webkit-transition: all 160ms 0ms ease-in-out;
    -moz-transition: all 160ms 0ms ease-in-out;
    -ms-transition: all 160ms 0ms ease-in-out;
    -o-transition: all 160ms 0ms ease-in-out;
    transition: all 160ms 0ms ease-in-out; }
    .site-works .listable li:hover {
      transform: scale(1.1);
      box-shadow: 0 0 48px rgba(0, 0, 0, 0.16); }
    @media screen and (max-width: 768px) {
      .site-works .listable li {
        margin: 0 0 24px;
        width: auto;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.08); }
        .site-works .listable li:last-child {
          margin: 0; } }
    @media screen and (min-width: 769px) {
      .site-works .listable li:nth-child(1), .site-works .listable li:nth-child(2) {
        margin: 0; } }
    .site-works .listable li .bg {
      height: 220px;
      background-position: center;
      background-size: cover; }
      @media screen and (max-width: 768px) {
        .site-works .listable li .bg {
          height: 150px; } }
  .site-works .listable h3 {
    padding: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      .site-works .listable h3 {
        padding: 10px 15px;
        font-size: 15px; } }

.works-component .profile {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 64px; }
  @media screen and (max-width: 768px) {
    .works-component .profile {
      display: block;
      margin: 6px 0 32px; } }
  .works-component .profile .bg {
    width: 480px;
    height: 290px;
    background-position: center;
    background-size: cover; }
    @media screen and (max-width: 768px) {
      .works-component .profile .bg {
        width: 100%;
        height: 200px;
        margin: 0 0 24px; } }
  .works-component .profile dl {
    width: 480px;
    font-size: 15px; }
    @media screen and (max-width: 768px) {
      .works-component .profile dl {
        width: 100%;
        font-size: 13.5px; } }
    .works-component .profile dl dt {
      font-size: 32px;
      font-weight: 700;
      margin: 0 0 12px; }
      @media screen and (max-width: 768px) {
        .works-component .profile dl dt {
          font-size: 20px;
          text-align: center; } }

.works-component p.txt {
  border: rgba(0, 0, 0, 0.04) 4px solid;
  padding: 32px;
  font-size: 16px;
  border-radius: 6px; }
  @media screen and (max-width: 768px) {
    .works-component p.txt {
      font-size: 13.5px;
      padding: 15px; } }

#site-body.hidden {
  overflow: hidden; }

@media screen and (max-width: 768px) {
  .display {
    padding: 0 15px; } }

.site-module.wrap {
  padding: 96px 0; }
  @media screen and (max-width: 768px) {
    .site-module.wrap {
      padding: 40px 0; } }
  .site-module.wrap.no-top {
    padding-top: 0; }
  .site-module.wrap.no-bottom {
    padding-bottom: 0; }
  .site-module.wrap.border {
    border-bottom: #e5e5e5 1px solid; }
  .site-module.wrap.bg {
    background: #f6f6f6; }

.site-module .hgroup {
  text-align: center;
  margin: 0 0 40px; }
  @media screen and (max-width: 768px) {
    .site-module .hgroup {
      margin: 0 0 24px; } }
  .site-module .hgroup h2 {
    font-weight: 900;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    display: inline-block; }
    @media screen and (max-width: 768px) {
      .site-module .hgroup h2 {
        font-size: 12px; } }
    .site-module .hgroup h2:before {
      content: attr(data-text);
      font-size: 40px;
      letter-spacing: .08em;
      display: block;
      margin: 0 0 12px;
      padding: 0 0 10px;
      border-bottom: #000 2px solid; }
      @media screen and (max-width: 768px) {
        .site-module .hgroup h2:before {
          font-size: 24px; } }

.site-container {
  position: relative;
  top: 100vh;
  left: 0;
  background: #FFF;
  z-index: 10; }

.site-business .relative {
  position: relative; }
  .site-business .relative .bg {
    width: 72%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover; }
    @media screen and (max-width: 768px) {
      .site-business .relative .bg {
        width: 100%;
        height: 200px;
        position: relative; } }
  .site-business .relative .texter {
    padding: 80px 0;
    padding-left: calc(512px - 64px); }
    @media screen and (max-width: 768px) {
      .site-business .relative .texter {
        padding: 32px 0;
        border-bottom: #e5e5e5 1px solid; } }
  .site-business .relative dl {
    padding: 64px;
    padding-right: 0;
    background: #FFF; }
    @media screen and (max-width: 768px) {
      .site-business .relative dl {
        padding: 0 15px; } }
    .site-business .relative dl dt {
      font-size: 28px;
      line-height: 1.6;
      font-weight: 700;
      margin: 0 0 16px; }
      @media screen and (max-width: 768px) {
        .site-business .relative dl dt {
          font-size: 18px;
          text-align: center; } }
    .site-business .relative dl dd {
      text-align: justify; }
      @media screen and (max-width: 768px) {
        .site-business .relative dl dd {
          padding: 0 15px; } }
      .site-business .relative dl dd.btns {
        margin: 32px 0 0; }
        @media screen and (max-width: 768px) {
          .site-business .relative dl dd.btns {
            margin: 24px 0 0;
            text-align: center; } }

.site-business .repbox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 80px; }
  @media screen and (max-width: 768px) {
    .site-business .repbox {
      margin: 0 0 32px;
      display: block; } }
  .site-business .repbox .bg {
    width: 48.5%;
    height: 300px;
    background-position: center;
    background-size: cover; }
    @media screen and (max-width: 768px) {
      .site-business .repbox .bg {
        width: auto;
        height: 250px;
        margin: 0 0 20px; } }
  .site-business .repbox dl {
    width: 48.5%; }
    @media screen and (max-width: 768px) {
      .site-business .repbox dl {
        width: auto; } }
    .site-business .repbox dl dd {
      margin: 0 0 24px;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .site-business .repbox dl dd {
          font-size: 14px; } }
    .site-business .repbox dl dt {
      text-align: right; }
      .site-business .repbox dl dt small {
        display: block;
        font-size: 13px; }
        @media screen and (max-width: 768px) {
          .site-business .repbox dl dt small {
            font-size: 12px; } }
      .site-business .repbox dl dt strong {
        display: block;
        font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.04em;
        font-size: 24px;
        line-height: 1; }
        @media screen and (max-width: 768px) {
          .site-business .repbox dl dt strong {
            font-size: 18px; } }

.site-picture .swiper-wrapper {
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); }

.site-picture .swiper-slide {
  width: 480px;
  height: 320px;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 768px) {
    .site-picture .swiper-slide {
      width: calc(100vw - 30px);
      height: 200px; } }
  .site-picture .swiper-slide span {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 8px 0;
    background: rgba(0, 0, 0, 0.72);
    color: #FFF;
    text-align: center;
    font-size: 15px;
    transform: translateY(40px);
    opacity: 0;
    -webkit-transition: all 160ms 0ms ease-in-out;
    -moz-transition: all 160ms 0ms ease-in-out;
    -ms-transition: all 160ms 0ms ease-in-out;
    -o-transition: all 160ms 0ms ease-in-out;
    transition: all 160ms 0ms ease-in-out; }
    @media screen and (max-width: 768px) {
      .site-picture .swiper-slide span {
        transform: translateY(0);
        opacity: 1;
        font-size: 13px; } }
  .site-picture .swiper-slide:hover span {
    transform: translateY(0);
    opacity: 1; }

.site-kussion {
  padding: 128px 0 96px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative; }
  @media screen and (max-width: 768px) {
    .site-kussion {
      padding: 72px 0 56px; } }
  .site-kussion:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    opacity: .64; }
  .site-kussion:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 32px 48px 0 48px;
    border-color: #f6f6f6 transparent transparent transparent;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  .site-kussion dl {
    position: relative;
    z-index: 2;
    color: #FFF;
    text-align: center; }
    .site-kussion dl dt {
      font-size: 32px;
      font-weight: 700;
      margin: 0 0 8px; }
      @media screen and (max-width: 768px) {
        .site-kussion dl dt {
          font-size: 18px; } }
    .site-kussion dl dd {
      font-size: 18px; }
      @media screen and (max-width: 768px) {
        .site-kussion dl dd {
          font-size: 13px;
          margin: 0 15px;
          text-align: justify; } }
    .site-kussion dl .btns a {
      background: none !important;
      border: #FFF 1px solid !important;
      color: #FFF !important;
      -webkit-transition: all 160ms 0ms ease-in-out;
      -moz-transition: all 160ms 0ms ease-in-out;
      -ms-transition: all 160ms 0ms ease-in-out;
      -o-transition: all 160ms 0ms ease-in-out;
      transition: all 160ms 0ms ease-in-out; }
      .site-kussion dl .btns a:hover {
        box-shadow: 0 0 32px rgba(255, 255, 255, 0.24); }

.btns {
  text-align: center;
  margin: 48px 0 0; }
  @media screen and (max-width: 768px) {
    .btns {
      margin: 24px 0 0; } }
  .btns a {
    display: inline-block;
    background: #000;
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    padding: 12px 88px;
    border-radius: 999px; }
    @media screen and (max-width: 768px) {
      .btns a {
        display: block;
        font-size: 16px;
        padding: 11px 0; } }
    .btns a:hover {
      background: #333333; }
    .btns a i {
      display: inline-block;
      margin: 0 12px 0 0; }

.googlemap {
  filter: grayscale(100);
  position: relative;
  top: 0;
  left: 0; }
  .googlemap:before {
    content: '';
    width: 100%;
    height: 40px;
    opacity: .024;
    position: absolute;
    background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.99) 1%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.99) 1%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.99) 1%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none; }
    @media screen and (max-width: 768px) {
      .googlemap:before {
        height: 15px; } }
  .googlemap iframe {
    width: 100%;
    height: 400px;
    vertical-align: bottom; }
    @media screen and (max-width: 768px) {
      .googlemap iframe {
        height: 200px; } }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.92); }
  .overlay.white {
    background: #FFF; }
    .overlay.white .inner {
      width: 100%;
      height: 100%;
      max-height: 100%;
      top: 0;
      left: 0;
      padding: 0;
      background: none;
      transform: translateX(0) translateY(0); }
      .overlay.white .inner .contanier {
        width: 1024px;
        margin: 0 auto;
        padding: 144px 0; }
        @media screen and (max-width: 768px) {
          .overlay.white .inner .contanier {
            width: auto;
            padding: 56px 15px; } }
    .overlay.white .close:before, .overlay.white .close:after {
      background: #000; }
  .overlay .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 800px;
    max-height: 80%;
    padding: 40px;
    padding-top: 0;
    background: #FFF;
    transform: translateX(-50%) translateY(-50%);
    overflow: auto; }
    @media screen and (max-width: 768px) {
      .overlay .inner {
        width: calc(100% - 30px);
        padding: 15px;
        padding-top: 0; } }
  .overlay h2 {
    background: #f2f2f2;
    color: #000;
    font-size: 24px;
    font-weight: 700;
    padding: 14px 20px;
    border-radius: 4px;
    margin: 0 0 24px;
    clear: both; }
    @media screen and (max-width: 768px) {
      .overlay h2 {
        font-size: 16px;
        padding: 11px 15px;
        margin: 0 0 15px; } }
    .overlay h2 i {
      display: inline-block;
      margin: 0 12px 0 0; }
      .overlay h2 i.rvs {
        transform: rotateY(180deg); }
  .overlay h3 {
    font-size: 24px;
    font-weight: 700;
    border-bottom: #000 2px solid;
    margin: 0 0 40px;
    padding: 40px 0 4px;
    position: sticky;
    top: 0;
    background: #FFF; }
    @media screen and (max-width: 768px) {
      .overlay h3 {
        font-size: 16px;
        margin: 0 0 15px;
        padding: 15px 0 4px; } }
  .overlay .privacy {
    padding: 15px;
    height: 200px;
    overflow: auto;
    border: #e5e5e5 2px solid;
    border-radius: 4px;
    font-size: 13px; }
    @media screen and (max-width: 768px) {
      .overlay .privacy {
        padding: 0;
        border: none;
        height: 110px;
        font-size: 12px; } }
    .overlay .privacy dl {
      margin: 24px 0 0; }
      .overlay .privacy dl dt {
        font-size: 15px;
        font-weight: 700; }
  .overlay .bottoms {
    padding: 0 0 40px; }
    .overlay .bottoms .alert {
      margin: 15px 15px 0;
      font-size: 12px;
      text-align: center; }
      .overlay .bottoms .alert.err {
        margin: 40px 15px;
        font-weight: 700;
        font-size: 20px; }
  .overlay .table-style {
    width: 100%;
    border-collapse: collapse;
    border: #e5e5e5 1px solid; }
    @media screen and (min-width: 769px) {
      .overlay .table-style.form-style {
        border: none; } }
    @media screen and (min-width: 769px) {
      .overlay .table-style.form-style tbody tr {
        border: none;
        border-bottom: #e5e5e5 1px solid; }
        .overlay .table-style.form-style tbody tr:first-child th, .overlay .table-style.form-style tbody tr:first-child td {
          padding-top: 0; } }
    @media screen and (min-width: 769px) {
      .overlay .table-style.form-style tbody tr th, .overlay .table-style.form-style tbody tr td {
        padding: 24px 0;
        vertical-align: middle; } }
    .overlay .table-style.form-style tbody tr th input[type=text],
    .overlay .table-style.form-style tbody tr th input[type=email],
    .overlay .table-style.form-style tbody tr th input[type=tel],
    .overlay .table-style.form-style tbody tr th textarea,
    .overlay .table-style.form-style tbody tr th select, .overlay .table-style.form-style tbody tr td input[type=text],
    .overlay .table-style.form-style tbody tr td input[type=email],
    .overlay .table-style.form-style tbody tr td input[type=tel],
    .overlay .table-style.form-style tbody tr td textarea,
    .overlay .table-style.form-style tbody tr td select {
      font-family: 'Lato',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.024em;
      font-size: 15px;
      padding: 5px 8px;
      border: #e5e5e5 2px solid;
      border-radius: 4px;
      width: 100%;
      -webkit-transition: all 120ms 0ms ease-in-out;
      -moz-transition: all 120ms 0ms ease-in-out;
      -ms-transition: all 120ms 0ms ease-in-out;
      -o-transition: all 120ms 0ms ease-in-out;
      transition: all 120ms 0ms ease-in-out; }
      .overlay .table-style.form-style tbody tr th input[type=text]:focus,
      .overlay .table-style.form-style tbody tr th input[type=email]:focus,
      .overlay .table-style.form-style tbody tr th input[type=tel]:focus,
      .overlay .table-style.form-style tbody tr th textarea:focus,
      .overlay .table-style.form-style tbody tr th select:focus, .overlay .table-style.form-style tbody tr td input[type=text]:focus,
      .overlay .table-style.form-style tbody tr td input[type=email]:focus,
      .overlay .table-style.form-style tbody tr td input[type=tel]:focus,
      .overlay .table-style.form-style tbody tr td textarea:focus,
      .overlay .table-style.form-style tbody tr td select:focus {
        box-shadow: 0 0 64px rgba(0, 0, 0, 0.32);
        border: #FFF 2px solid; }
    .overlay .table-style.form-style tbody tr th textarea, .overlay .table-style.form-style tbody tr td textarea {
      height: 160px; }
    .overlay .table-style.form-style tbody tr th select, .overlay .table-style.form-style tbody tr td select {
      width: auto; }
    .overlay .table-style.form-style tbody tr th label, .overlay .table-style.form-style tbody tr td label {
      max-width: 32%;
      display: inline-block;
      margin: 0 12px 0 0; }
      @media screen and (max-width: 768px) {
        .overlay .table-style.form-style tbody tr th label, .overlay .table-style.form-style tbody tr td label {
          max-width: 100%; } }
      .overlay .table-style.form-style tbody tr th label input, .overlay .table-style.form-style tbody tr td label input {
        margin: 0 8px 0 0; }
      .overlay .table-style.form-style tbody tr th label select, .overlay .table-style.form-style tbody tr td label select {
        margin: 0 8px 0 0; }
      .overlay .table-style.form-style tbody tr th label:last-child, .overlay .table-style.form-style tbody tr td label:last-child {
        margin: 0; }
    @media screen and (min-width: 769px) {
      .overlay .table-style.form-style tbody tr th {
        width: 30%; } }
    @media screen and (max-width: 768px) {
      .overlay .table-style.form-style tbody tr th {
        border-bottom: #e5e5e5 2px solid; } }
    .overlay .table-style.form-style tbody tr th.not:after {
      display: none; }
    .overlay .table-style.form-style tbody tr th:after {
      content: '必須';
      background: #000;
      color: #FFF;
      font-size: 12px;
      padding: 1px 16px;
      float: right;
      border-radius: 4px;
      margin: 3px 0 0; }
    @media screen and (min-width: 769px) {
      .overlay .table-style.form-style tbody tr td {
        padding-left: 40px; } }
    @media screen and (max-width: 768px) {
      .overlay .table-style {
        display: block; } }
    @media screen and (max-width: 768px) {
      .overlay .table-style tbody {
        display: block; }
        .overlay .table-style tbody tr, .overlay .table-style tbody th, .overlay .table-style tbody td {
          display: block; } }
    .overlay .table-style tbody tr {
      border: #e5e5e5 1px solid; }
      .overlay .table-style tbody tr th, .overlay .table-style tbody tr td {
        padding: 24px;
        vertical-align: middle;
        text-align: left;
        font-size: 16px; }
        @media screen and (max-width: 768px) {
          .overlay .table-style tbody tr th, .overlay .table-style tbody tr td {
            padding: 12px 15px;
            font-size: 13px; } }
      .overlay .table-style tbody tr th {
        width: 20%; }
        @media screen and (max-width: 768px) {
          .overlay .table-style tbody tr th {
            width: auto;
            background: #f2f2f2; } }
      .overlay .table-style tbody tr td {
        padding-left: 48px; }
        @media screen and (max-width: 768px) {
          .overlay .table-style tbody tr td {
            padding: 12px 15px; } }
  .overlay .close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    z-index: 9999; }
    @media screen and (max-width: 768px) {
      .overlay .close {
        top: 15px;
        right: 15px;
        width: 32px;
        height: 32px; } }
    .overlay .close:before, .overlay .close:after {
      content: '';
      width: 100%;
      height: 1px;
      background: #FFF;
      position: absolute;
      top: 50%;
      left: 0;
      transform: rotate(45deg);
      -webkit-transition: all 160ms 0ms ease-in-out;
      -moz-transition: all 160ms 0ms ease-in-out;
      -ms-transition: all 160ms 0ms ease-in-out;
      -o-transition: all 160ms 0ms ease-in-out;
      transition: all 160ms 0ms ease-in-out; }
    .overlay .close:after {
      transform: rotate(-45deg); }
    .overlay .close:hover:before, .overlay .close:hover:after {
      transform: rotate(0deg); }

.formsend {
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  background: #FFF;
  border-radius: 999px;
  padding: 14px 56px;
  z-index: 9999;
  color: #000;
  font-size: 20px;
  font-weight: 700;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.16);
  -webkit-transition: all 600ms 0ms ease-in-out;
  -moz-transition: all 600ms 0ms ease-in-out;
  -ms-transition: all 600ms 0ms ease-in-out;
  -o-transition: all 600ms 0ms ease-in-out;
  transition: all 600ms 0ms ease-in-out; }
  @media screen and (max-width: 768px) {
    .formsend {
      padding: 15px;
      font-size: 17px;
      text-align: center;
      width: 300px; } }
  .formsend.active {
    top: 100px; }

.scale-enter-active, .scale-leave-active {
  transition: 600ms; }

.scale-enter, .scale-leave-to {
  opacity: 0;
  transform: scale(1.1); }

.move-enter-active, .move-leave-active {
  transition: 600ms cubic-bezier(0.86, 0, 0.07, 1); }

.move-enter, .move-leave-to {
  transform: translateX(100%); }

#demo-color {
  padding: 20px;
  padding-right: 32px;
  position: fixed;
  right: 0;
  bottom: -10px;
  z-index: 10;
  border-radius: 8px 0 0 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 200ms 0ms ease-in-out;
  -moz-transition: all 200ms 0ms ease-in-out;
  -ms-transition: all 200ms 0ms ease-in-out;
  -o-transition: all 200ms 0ms ease-in-out;
  transition: all 200ms 0ms ease-in-out; }
  @media screen and (max-width: 768px) {
    #demo-color {
      display: block;
      border-radius: 0;
      width: 100%;
      padding: 15px; } }
  #demo-color.active {
    background: #FFF;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.12);
    bottom: 0; }
  #demo-color i.close {
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: #000;
    color: #FFF;
    text-align: center;
    border-radius: 50%;
    font-size: 18px;
    position: absolute;
    top: -16px;
    right: 8px;
    cursor: pointer; }
  #demo-color p {
    margin: 0 20px 0 0; }
    @media screen and (max-width: 768px) {
      #demo-color p {
        margin: 0 0 10px;
        text-align: center;
        font-size: 13px; } }
  #demo-color ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    justify-content: center; }
    #demo-color ul li {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      list-style: none;
      margin: 0 8px;
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out;
      cursor: pointer; }
      @media screen and (max-width: 768px) {
        #demo-color ul li {
          margin: 0 4px; } }
      #demo-color ul li.active {
        transform: scale(0.8); }

.color-blue .custum-color {
  color: #003fa8 !important; }

.color-blue .custum-bg {
  background-color: #003fa8 !important; }

.color-blue a.custum-color:hover {
  color: #0052db !important; }

.color-blue a.custum-bg:hover {
  background-color: #0052db !important; }

.color-blue .site-picture .swiper-slide span {
  background: rgba(0, 48, 127, 0.72); }

.color-blue .site-recruit .list > li i {
  border-color: #003fa8;
  color: #003fa8; }

.color-blue .site-recruit .list > li .meta li::before {
  background-color: #003fa8; }

.color-blue #site-footer {
  background-color: #003fa8 !important; }
  .color-blue #site-footer #pageup {
    color: #FFF; }

.color-blue .recruit-component .tag li {
  border: #003fa8 1px solid;
  background: #FFF;
  color: #003fa8; }

.color-blue .voice-component .profile dl dd.date {
  border-color: #003fa8;
  color: #003fa8; }

.color-blue .voice-component .profile dl dd.job {
  background-color: #003fa8;
  border-color: #003fa8; }

.color-blue .voice-component .faq li dl dd::before {
  border-color: #003fa8;
  color: #003fa8; }

.color-blue .voice-component .faq li dl dt::before {
  background-color: #003fa8; }

.color-blue .overlay .table-style.form-style tbody tr th::after {
  background-color: #003fa8; }

.color-blue .overlay h2 i {
  color: #003fa8; }

.color-sky .custum-color {
  color: #0093e8 !important; }

.color-sky .custum-bg {
  background-color: #0093e8 !important; }

.color-sky a.custum-color:hover {
  color: #1cacff !important; }

.color-sky a.custum-bg:hover {
  background-color: #1cacff !important; }

.color-sky .site-picture .swiper-slide span {
  background: rgba(0, 121, 191, 0.72); }

.color-sky .site-recruit .list > li i {
  border-color: #0093e8;
  color: #0093e8; }

.color-sky .site-recruit .list > li .meta li::before {
  background-color: #0093e8; }

.color-sky #site-footer {
  background-color: #0093e8 !important; }
  .color-sky #site-footer #pageup {
    color: #FFF; }

.color-sky .recruit-component .tag li {
  border: #0093e8 1px solid;
  background: #FFF;
  color: #0093e8; }

.color-sky .voice-component .profile dl dd.date {
  border-color: #0093e8;
  color: #0093e8; }

.color-sky .voice-component .profile dl dd.job {
  background-color: #0093e8;
  border-color: #0093e8; }

.color-sky .voice-component .faq li dl dd::before {
  border-color: #0093e8;
  color: #0093e8; }

.color-sky .voice-component .faq li dl dt::before {
  background-color: #0093e8; }

.color-sky .overlay .table-style.form-style tbody tr th::after {
  background-color: #0093e8; }

.color-sky .overlay h2 i {
  color: #0093e8; }

.color-green .custum-color {
  color: #00873c !important; }

.color-green .custum-bg {
  background-color: #00873c !important; }

.color-green a.custum-color:hover {
  color: #00ba53 !important; }

.color-green a.custum-bg:hover {
  background-color: #00ba53 !important; }

.color-green .site-picture .swiper-slide span {
  background: rgba(0, 94, 42, 0.72); }

.color-green .site-recruit .list > li i {
  border-color: #00873c;
  color: #00873c; }

.color-green .site-recruit .list > li .meta li::before {
  background-color: #00873c; }

.color-green #site-footer {
  background-color: #00873c !important; }
  .color-green #site-footer #pageup {
    color: #FFF; }

.color-green .recruit-component .tag li {
  border: #00873c 1px solid;
  background: #FFF;
  color: #00873c; }

.color-green .voice-component .profile dl dd.date {
  border-color: #00873c;
  color: #00873c; }

.color-green .voice-component .profile dl dd.job {
  background-color: #00873c;
  border-color: #00873c; }

.color-green .voice-component .faq li dl dd::before {
  border-color: #00873c;
  color: #00873c; }

.color-green .voice-component .faq li dl dt::before {
  background-color: #00873c; }

.color-green .overlay .table-style.form-style tbody tr th::after {
  background-color: #00873c; }

.color-green .overlay h2 i {
  color: #00873c; }

.color-lightgreen .custum-color {
  color: #0baba2 !important; }

.color-lightgreen .custum-bg {
  background-color: #0baba2 !important; }

.color-lightgreen a.custum-color:hover {
  color: #0edbcf !important; }

.color-lightgreen a.custum-bg:hover {
  background-color: #0edbcf !important; }

.color-lightgreen .site-picture .swiper-slide span {
  background: rgba(9, 133, 126, 0.72); }

.color-lightgreen .site-recruit .list > li i {
  border-color: #0baba2;
  color: #0baba2; }

.color-lightgreen .site-recruit .list > li .meta li::before {
  background-color: #0baba2; }

.color-lightgreen #site-footer {
  background-color: #0baba2 !important; }
  .color-lightgreen #site-footer #pageup {
    color: #FFF; }

.color-lightgreen .recruit-component .tag li {
  border: #0baba2 1px solid;
  background: #FFF;
  color: #0baba2; }

.color-lightgreen .voice-component .profile dl dd.date {
  border-color: #0baba2;
  color: #0baba2; }

.color-lightgreen .voice-component .profile dl dd.job {
  background-color: #0baba2;
  border-color: #0baba2; }

.color-lightgreen .voice-component .faq li dl dd::before {
  border-color: #0baba2;
  color: #0baba2; }

.color-lightgreen .voice-component .faq li dl dt::before {
  background-color: #0baba2; }

.color-lightgreen .overlay .table-style.form-style tbody tr th::after {
  background-color: #0baba2; }

.color-lightgreen .overlay h2 i {
  color: #0baba2; }

.color-khaki .custum-color {
  color: #a88e00 !important; }

.color-khaki .custum-bg {
  background-color: #a88e00 !important; }

.color-khaki a.custum-color:hover {
  color: #dbb900 !important; }

.color-khaki a.custum-bg:hover {
  background-color: #dbb900 !important; }

.color-khaki .site-picture .swiper-slide span {
  background: rgba(127, 108, 0, 0.72); }

.color-khaki .site-recruit .list > li i {
  border-color: #a88e00;
  color: #a88e00; }

.color-khaki .site-recruit .list > li .meta li::before {
  background-color: #a88e00; }

.color-khaki #site-footer {
  background-color: #a88e00 !important; }
  .color-khaki #site-footer #pageup {
    color: #FFF; }

.color-khaki .recruit-component .tag li {
  border: #a88e00 1px solid;
  background: #FFF;
  color: #a88e00; }

.color-khaki .voice-component .profile dl dd.date {
  border-color: #a88e00;
  color: #a88e00; }

.color-khaki .voice-component .profile dl dd.job {
  background-color: #a88e00;
  border-color: #a88e00; }

.color-khaki .voice-component .faq li dl dd::before {
  border-color: #a88e00;
  color: #a88e00; }

.color-khaki .voice-component .faq li dl dt::before {
  background-color: #a88e00; }

.color-khaki .overlay .table-style.form-style tbody tr th::after {
  background-color: #a88e00; }

.color-khaki .overlay h2 i {
  color: #a88e00; }

.color-orange .custum-color {
  color: #f07c00 !important; }

.color-orange .custum-bg {
  background-color: #f07c00 !important; }

.color-orange a.custum-color:hover {
  color: #ff9524 !important; }

.color-orange a.custum-bg:hover {
  background-color: #ff9524 !important; }

.color-orange .site-picture .swiper-slide span {
  background: rgba(199, 103, 0, 0.72); }

.color-orange .site-recruit .list > li i {
  border-color: #f07c00;
  color: #f07c00; }

.color-orange .site-recruit .list > li .meta li::before {
  background-color: #f07c00; }

.color-orange #site-footer {
  background-color: #f07c00 !important; }
  .color-orange #site-footer #pageup {
    color: #FFF; }

.color-orange .recruit-component .tag li {
  border: #f07c00 1px solid;
  background: #FFF;
  color: #f07c00; }

.color-orange .voice-component .profile dl dd.date {
  border-color: #f07c00;
  color: #f07c00; }

.color-orange .voice-component .profile dl dd.job {
  background-color: #f07c00;
  border-color: #f07c00; }

.color-orange .voice-component .faq li dl dd::before {
  border-color: #f07c00;
  color: #f07c00; }

.color-orange .voice-component .faq li dl dt::before {
  background-color: #f07c00; }

.color-orange .overlay .table-style.form-style tbody tr th::after {
  background-color: #f07c00; }

.color-orange .overlay h2 i {
  color: #f07c00; }

.color-red .custum-color {
  color: #b80c0c !important; }

.color-red .custum-bg {
  background-color: #b80c0c !important; }

.color-red a.custum-color:hover {
  color: #e80f0f !important; }

.color-red a.custum-bg:hover {
  background-color: #e80f0f !important; }

.color-red .site-picture .swiper-slide span {
  background: rgba(146, 10, 10, 0.72); }

.color-red .site-recruit .list > li i {
  border-color: #b80c0c;
  color: #b80c0c; }

.color-red .site-recruit .list > li .meta li::before {
  background-color: #b80c0c; }

.color-red #site-footer {
  background-color: #b80c0c !important; }
  .color-red #site-footer #pageup {
    color: #FFF; }

.color-red .recruit-component .tag li {
  border: #b80c0c 1px solid;
  background: #FFF;
  color: #b80c0c; }

.color-red .voice-component .profile dl dd.date {
  border-color: #b80c0c;
  color: #b80c0c; }

.color-red .voice-component .profile dl dd.job {
  background-color: #b80c0c;
  border-color: #b80c0c; }

.color-red .voice-component .faq li dl dd::before {
  border-color: #b80c0c;
  color: #b80c0c; }

.color-red .voice-component .faq li dl dt::before {
  background-color: #b80c0c; }

.color-red .overlay .table-style.form-style tbody tr th::after {
  background-color: #b80c0c; }

.color-red .overlay h2 i {
  color: #b80c0c; }

.color-pink .custum-color {
  color: #f96a6e !important; }

.color-pink .custum-bg {
  background-color: #f96a6e !important; }

.color-pink a.custum-color:hover {
  color: #fb9b9e !important; }

.color-pink a.custum-bg:hover {
  background-color: #fb9b9e !important; }

.color-pink .site-picture .swiper-slide span {
  background: rgba(247, 67, 72, 0.72); }

.color-pink .site-recruit .list > li i {
  border-color: #f96a6e;
  color: #f96a6e; }

.color-pink .site-recruit .list > li .meta li::before {
  background-color: #f96a6e; }

.color-pink #site-footer {
  background-color: #f96a6e !important; }
  .color-pink #site-footer #pageup {
    color: #FFF; }

.color-pink .recruit-component .tag li {
  border: #f96a6e 1px solid;
  background: #FFF;
  color: #f96a6e; }

.color-pink .voice-component .profile dl dd.date {
  border-color: #f96a6e;
  color: #f96a6e; }

.color-pink .voice-component .profile dl dd.job {
  background-color: #f96a6e;
  border-color: #f96a6e; }

.color-pink .voice-component .faq li dl dd::before {
  border-color: #f96a6e;
  color: #f96a6e; }

.color-pink .voice-component .faq li dl dt::before {
  background-color: #f96a6e; }

.color-pink .overlay .table-style.form-style tbody tr th::after {
  background-color: #f96a6e; }

.color-pink .overlay h2 i {
  color: #f96a6e; }

.color-purple .custum-color {
  color: #75049a !important; }

.color-purple .custum-bg {
  background-color: #75049a !important; }

.color-purple a.custum-color:hover {
  color: #9b05cc !important; }

.color-purple a.custum-bg:hover {
  background-color: #9b05cc !important; }

.color-purple .site-picture .swiper-slide span {
  background: rgba(87, 3, 114, 0.72); }

.color-purple .site-recruit .list > li i {
  border-color: #75049a;
  color: #75049a; }

.color-purple .site-recruit .list > li .meta li::before {
  background-color: #75049a; }

.color-purple #site-footer {
  background-color: #75049a !important; }
  .color-purple #site-footer #pageup {
    color: #FFF; }

.color-purple .recruit-component .tag li {
  border: #75049a 1px solid;
  background: #FFF;
  color: #75049a; }

.color-purple .voice-component .profile dl dd.date {
  border-color: #75049a;
  color: #75049a; }

.color-purple .voice-component .profile dl dd.job {
  background-color: #75049a;
  border-color: #75049a; }

.color-purple .voice-component .faq li dl dd::before {
  border-color: #75049a;
  color: #75049a; }

.color-purple .voice-component .faq li dl dt::before {
  background-color: #75049a; }

.color-purple .overlay .table-style.form-style tbody tr th::after {
  background-color: #75049a; }

.color-purple .overlay h2 i {
  color: #75049a; }

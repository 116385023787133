@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#000;
$link:#D02;
$border:#e5e5e5;


/* SITE SETTING
------------------------------------------------------------*/
$header-pc: 80px;
$header-sp: 60px;

$width :1024px;
$responsive: 768px;

@media screen and (min-width:$responsive + 1){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$responsive){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "page/firstview";
@import "page/news";
@import "page/recruit";
@import "page/works";

#site-body{
	&.hidden{
		overflow: hidden;
	}
}

.display{
	@media screen and (max-width:$responsive){
		padding: 0 15px;	
	}
}

.site-module{
	&.wrap{
		padding: 96px 0;
		@media screen and (max-width:$responsive){
			padding: 40px 0;	
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
		&.border{
			border-bottom: $border 1px solid;
		}
		&.bg{
			background: #f6f6f6;
		}
	}
	.hgroup{
		text-align: center;
		margin: 0 0 40px;
		@media screen and (max-width:$responsive){
			margin: 0 0 24px;
		}
		h2{
			font-weight: 900;
			font-size: 16px;
			line-height: 1;
			text-align: center;
			display:inline-block;
			@media screen and (max-width:$responsive){
				font-size: 12px;	
			}
			&:before{
				content: attr(data-text);
				font-size: 40px;
				letter-spacing: .08em;
				display: block;
				margin: 0 0 12px;
				padding: 0 0 10px;
				border-bottom: $normal 2px solid;
				@media screen and (max-width:$responsive){
					font-size: 24px;
				}
			}
		}
	}
}

.site-container{
	position: relative;
	top: 100vh;
	left: 0;
	background: #FFF;
	z-index: 10;
}

.site-business{
	.relative{
		position: relative;
		.bg{
			width: 72%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-position: center;
			background-size: cover;
			@media screen and (max-width:$responsive){
				width: 100%;
				height: 200px;
				position: relative;
			}
		}
		.texter{
			padding: 80px 0;
			padding-left: calc(#{$width / 2} - 64px);
			@media screen and (max-width:$responsive){
				padding: 32px 0;
				border-bottom: $border 1px solid;
			}
		}
		dl{
			padding: 64px;
			padding-right: 0;
			background: #FFF;
			@media screen and (max-width:$responsive){
				padding: 0 15px;	
			}
			dt{
				font-size: 28px;
				line-height: 1.6;
				font-weight: 700;
				margin: 0 0 16px;
				@media screen and (max-width:$responsive){
					font-size: 18px;
					text-align: center;
				}
			}
			dd{
				text-align: justify;
				@media screen and (max-width:$responsive){
					padding: 0 15px;
				}
				&.btns{
					margin: 32px 0 0;
					@media screen and (max-width:$responsive){
						margin: 24px 0 0;
						text-align: center;
					}
				}
			}
		}
	}
	.repbox{
		@include flex();
		margin: 0 0 80px;
		@media screen and (max-width:$responsive){
			margin: 0 0 32px;
			display: block;
		}
		.bg{
			width: 48.5%;
			height: 300px;
			background-position: center;
			background-size: cover;
			@media screen and (max-width:$responsive){
				width: auto;
				height: 250px;
				margin: 0 0 20px;
			}
		}
		dl{
			width: 48.5%;
			@media screen and (max-width:$responsive){
				width: auto;
			}
			dd{
				margin: 0 0 24px;
				font-size: 15px;
				@media screen and (max-width:$responsive){
					font-size: 14px;	
				}
			}
			dt{
				text-align: right;
				small{
					display: block;
					font-size: 13px;
					@media screen and (max-width:$responsive){
						font-size: 12px;
					}
				}
				strong{
					display: block;
					@include min();
					font-size: 24px;
					line-height: 1;
					@media screen and (max-width:$responsive){
						font-size: 18px;	
					}
				}
			}
		}
	}
}

.site-picture{
	.swiper-wrapper{
		transition-timing-function:cubic-bezier(0.86, 0, 0.07, 1);
	}
	.swiper-slide{
		width: 480px;
		height: 320px;
		background-position: center;
		background-size: cover;
		overflow: hidden;
		position: relative;
		@media screen and (max-width:$responsive){
			width: calc(100vw - 30px);
			height: 200px;
		}
		span{
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 8px 0;
			background: rgba(#000,.72);
			color: #FFF;
			text-align: center;
			font-size: 15px;
			transform: translateY(40px);
			opacity: 0;
			@include transition(160ms);
			@media screen and (max-width:$responsive){
				transform: translateY(0);
				opacity: 1;
				font-size: 13px;
			}
		}
		&:hover{
			span{
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
}

.site-kussion{
	padding: 128px 0 96px;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	position: relative;
	@media screen and (max-width:$responsive){
		padding: 72px 0 56px;
	}
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: #000;
		opacity: .64;
	}
	&:after{
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 32px 48px 0 48px;
		border-color: #f6f6f6 transparent transparent transparent;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	dl{
		position: relative;
		z-index: 2;
		color: #FFF;
		text-align: center;
		dt{
			font-size: 32px;
			font-weight: 700;
			margin: 0 0 8px;
			@media screen and (max-width:$responsive){
				font-size: 18px;
			}
		}
		dd{
			font-size: 18px;
			@media screen and (max-width:$responsive){
				font-size: 13px;
				margin: 0 15px;
				text-align: justify;
			}
		}
		.btns{
			a{
				background: none !important;
				border: #FFF 1px solid !important;
				color: #FFF !important;
				@include transition(160ms);
				&:hover{
					box-shadow: 0 0 32px rgba(#FFF,.24);
				}
			}
		}
	}
}

.btns{
    text-align: center;
    margin: 48px 0 0;
	@media screen and (max-width:$responsive){
		margin: 24px 0 0;	
	}
    a{
        display: inline-block;
        background: $normal;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        padding: 12px 88px;
        border-radius: 999px;
		@media screen and (max-width:$responsive){
			display: block;
			font-size: 16px;
			padding: 11px 0;
		}
        &:hover{
            background: lighten($normal , 20%);
        }
        i{
            display: inline-block;
            margin: 0 12px 0 0;
        }
    }
}

.googlemap{
	filter: grayscale(100);
	position: relative;
	top: 0;
	left: 0;
	&:before{
		content: '';
		width: 100%;
		height: 40px;
		opacity: .024;
		position: absolute;
		background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.99) 1%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.99) 1%,rgba(0,0,0,0) 100%);
		background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.99) 1%,rgba(0,0,0,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );
		top: 0;
		left: 0;
		z-index: 2;
		pointer-events: none;
		@media screen and (max-width:$responsive){
			height: 15px;	
		}
	}
	iframe{
		width: 100%;
		height: 400px;
		vertical-align: bottom;
		@media screen and (max-width:$responsive){
			height: 200px;	
		}
	}
}

.overlay{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: rgba(#000,.92);
	&.white{
		background: #FFF;
		.inner{
			width: 100%;
			height: 100%;
			max-height: 100%;
			top: 0;
			left: 0;
			padding: 0;
			background: none;
			transform: translateX(0) translateY(0);
			.contanier{
				width: $width;
				margin: 0 auto;
				padding: 144px 0;
				@media screen and (max-width:$responsive){
					width: auto;
					padding: 56px 15px;
				}
			}
		}
		.close{
			&:before,
			&:after{
				background: $normal;
			}
		}
	}
	
	$pad: 40px;
	$sp-pad: 15px;
	.inner{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 800px;
		max-height: 80%;
		padding: $pad;
		padding-top: 0;
		background: #FFF;
		transform: translateX(-50%) translateY(-50%);
		overflow: auto;
		@media screen and (max-width:$responsive){
			width: calc(100% - 30px);
			padding: $sp-pad;
			padding-top: 0;
		}
	}
	h2{
		background: #f2f2f2;
		color: $normal;
		font-size: 24px;
		font-weight: 700;
		padding: 14px 20px;
		border-radius: 4px;
		margin: 0 0 24px;
		clear: both;
		@media screen and (max-width:$responsive){
			font-size: 16px;
			padding: 11px 15px;
			margin: 0 0 15px;
		}
		i{
			display: inline-block;
			margin: 0 12px 0 0;
			&.rvs{
				transform: rotateY(180deg);
			}
		}
	}
	h3{
		font-size: 24px;
		font-weight: 700;
		border-bottom: $normal 2px solid;
		margin: 0 0 $pad;
		padding: $pad 0 4px;
		position: sticky;
		top: 0;
		background: #FFF;
		@media screen and (max-width:$responsive){
			font-size: 16px;
			margin: 0 0 $sp-pad;
			padding: $sp-pad 0 4px;
		}
	}
	.privacy{
		padding: 15px;
		height: 200px;
		overflow: auto;
		border: $border 2px solid;
		border-radius: 4px;
		font-size: 13px;
		@media screen and (max-width:$responsive){
			padding: 0;
			border: none;
			height: 110px;
			font-size: 12px;
		}
		dl{
			margin: 24px 0 0;
			dt{
				font-size: 15px;
				font-weight: 700;
			}
		}
	}
	.bottoms{
		padding: 0 0 40px;
		.alert{
			margin: 15px 15px 0;
			font-size: 12px;
			text-align: center;
			&.err{
				margin: 40px 15px;
				font-weight: 700;
				font-size: 20px;
			}
		}
	}
	.table-style{
		width: 100%;
		border-collapse: collapse;
		border: $border 1px solid;
		&.form-style{
			@media screen and (min-width:$responsive + 1px){
				border: none;
			}
			tbody{
				tr{
					@media screen and (min-width:$responsive + 1px){
						border: none;
						border-bottom: $border 1px solid;
						&:first-child{
							th,td{
								padding-top: 0;
							}
						}
					}
					th,td{
						@media screen and (min-width:$responsive + 1px){
							padding: 24px 0;
							vertical-align: middle;
						}
						input[type=text],
						input[type=email],
						input[type=tel],
						textarea,
						select{ 
							@include font();
							font-size: 15px;
							padding: 5px 8px;
							border: $border 2px solid;
							border-radius: 4px;
							width: 100%;
							@include transition(120ms);
							&:focus{
								box-shadow: 0 0 64px rgba(#000,.32);
								border: #FFF 2px solid;
							}
						}
						textarea{
							height: 160px;
						}
						select{
							width: auto;
						}
						label{
							max-width: 32%;
							display: inline-block;
							margin: 0 12px 0 0;
							@media screen and (max-width:$responsive){
								max-width: 100%;	
							}
							input{
								margin: 0 8px 0 0;
							}
							select{
								margin: 0 8px 0 0;
							}
							&:last-child{
								margin: 0;
							}
						}
					}
					th{
						@media screen and (min-width:$responsive + 1px){
							width: 30%;
						}
						@media screen and (max-width:$responsive){
							border-bottom: $border 2px solid;
						}
						&.not{
							&:after{
								display: none;
							}
						}
						&:after{
							content: '必須';
							background: $normal;
							color: #FFF;
							font-size: 12px;
							padding: 1px 16px;
							float: right;
							border-radius: 4px;
							margin: 3px 0 0;
						}
					}
					td{
						@media screen and (min-width:$responsive + 1px){
							padding-left: 40px;
						}
					}
				}
			}
		}
		@media screen and (max-width:$responsive){
			display: block;	
		}
		tbody{
			@media screen and (max-width:$responsive){
				display: block;
				tr,th,td{
					display: block;
				}
			}
			tr{
				border: $border 1px solid;
				th,td{
					padding: 24px;
					vertical-align: middle;
					text-align: left;
					font-size: 16px;
					@media screen and (max-width:$responsive){
						padding: 12px 15px;
						font-size: 13px;
					}
				}
				th{
					width: 20%;
					@media screen and (max-width:$responsive){
						width: auto;
						background: #f2f2f2;
					}
				}
				td{
					padding-left: 48px;
					@media screen and (max-width:$responsive){
						 padding: 12px 15px;	
					}
				}
			}
		}
	}
	.close{
		$size: 40px;
		width: $size;
		height: $size;
		position: absolute;
		top: 24px;
		right: 24px;
		cursor: pointer;
		z-index: 9999;
		@media screen and (max-width:$responsive){
			top: 15px;
			right: 15px;
			width: 32px;
			height: 32px;
		}
		&:before,
		&:after{
			content: '';
			width: 100%;
			height: 1px;
			background: #FFF;
			position: absolute;
			top: 50%;
			left: 0;
			transform: rotate(45deg);
			@include transition(160ms);
		}
		&:after{
			transform: rotate(-45deg);
		}
        &:hover{
			&:before,
			&:after{
            	transform: rotate(0deg);
			}
        }
	}
}
.formsend{
	position: fixed;
	top: -100px;
	left: 50%;
	transform: translateX(-50%);
	background: #FFF;
	border-radius: 999px;
	padding: 14px 56px;
	z-index: 9999;
	color: $normal;
	font-size: 20px;
	font-weight: 700;
	box-shadow: 0 0 32px rgba(#000,.16);
	@media screen and (max-width:$responsive){
		padding: 15px;
		font-size: 17px;
		text-align: center;
		width: 300px;
	}
	@include transition(600ms);
	&.active{
		top: 100px;
	}
}

.scale-enter-active, .scale-leave-active {
	transition: 600ms;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
	transform: scale(1.1);
}

.move-enter-active, .move-leave-active {
	transition: 600ms  cubic-bezier(0.86, 0, 0.07, 1) ;
}
.move-enter, .move-leave-to {
	transform: translateX(100%);
}


#demo-color{
	padding: 20px;
	padding-right: 32px;
	position: fixed;
	right: 0;
	bottom: -10px;
	z-index: 10;
	border-radius: 8px 0 0 0;
	@include flex();
	align-items: center;
	@include transition(200ms);
	@media screen and (max-width:$responsive){
		display: block;
		border-radius: 0;
		width: 100%;
		padding: 15px;
	}
	&.active{
		background: #FFF;
		box-shadow: 0 0 32px rgba(#000,.12);
		bottom: 0;
	}
	i.close{
		$size:32px;
		width: $size;
		height: $size;
		line-height: $size;
		background: $normal;
		color: #FFF;
		text-align: center;
		border-radius: 50%;
		font-size: 18px;
		position: absolute;
		top: -16px;
		right: 8px;
		cursor: pointer;
	}
	p{
		margin: 0 20px 0 0;
		@media screen and (max-width:$responsive){
			margin: 0 0 10px;
			text-align: center;
			font-size: 13px;
		}
	}
	ul{
		@include flex();
		justify-content: center;
		li{
			$size: 18px;
			width: $size;
			height: $size;
			border-radius: 50%;
			list-style: none;
			margin: 0 8px;
			@include transition(200ms);
			cursor: pointer;
			@media screen and (max-width:$responsive){
				margin: 0 4px;	
			}
			&.active{
				transform: scale(.8);
			}
		}
	}
}

@import "color/blue";
@import "color/sky";
@import "color/green";
@import "color/lightgreen";
@import "color/khaki";
@import "color/orange";
@import "color/red";
@import "color/pink";
@import "color/purple";


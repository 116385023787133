#firstview{
	position: fixed;
	top: $header-pc;
	left: 0;
	width: 100%;
	height: calc(100% - #{$header-pc});
	background: #000;
	z-index: 1;
	align-items: center;
	@media screen and (max-width:$responsive){
		top: $header-sp;
		height: calc(100% - #{$header-sp});
	}
	.concept{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(#000,.04);
		padding-left: 8%;
		color: #FFF;
		text-shadow: 0 0 32px rgba(#000,.64);
		z-index: 2;
		@include flex();
		align-items: center;
		font-weight: 900;
		dl{
			transform: translateY(-40px);
			dt{
				font-size: calc(48px + 32 * (100vw - 450px)/ 900);
				text-shadow: 0 0 32px rgba(#000,.16);
				line-height: 1.3;
			}
			dd{
				font-size: 24px;
				@media screen and (max-width:$responsive){
					font-size: 18px;
				}
			}
		}
	}
	.swiper-slide{
		height: calc(100vh - #{$header-pc});
		@media screen and (max-width:$responsive){
			height: calc(100vh - #{$header-sp});
		}
	}
    .swiper-slide-active .swipe-bg,
    .swiper-slide-duplicate-active .swipe-bg,
    .swiper-slide-prev .swipe-bg{
        animation: zoomUp 10s linear 0s 1 normal both;  
    }
    @keyframes zoomUp {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.16);
        }
    }
	.swipe-bg{
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: absolute;
		width: 100%;
		height: 100%;
	}
    .scroll{
        $size: 25vh;
		$sc: 0;
        position: absolute;
        right: 40px;
        bottom: 0;
		z-index: 2;
		width: 32px;
        height: $size;
		@media screen and (max-width:$responsive){
			right: 15px;	
		}
		i{
			position: absolute;
			left: 0;
			top: 0;
			text-align: right;
			white-space: nowrap;
			color: #FFF;
			font-size: 11px;
			line-height: 1;
			font-weight: 500;
			letter-spacing: .32em;
			-webkit-writing-mode: vertical-rl;
			-moz-writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
		}
		&:before,
        &:after{
            content: '';
            width: 1px;
            height: $size - $sc;
            background: #FFF;
            position: absolute;
            top: 0;
            right: 0;
        }
		&:after{
			background: $normal;
            animation: sc 3s infinite;
            @keyframes sc {
                0% {
                    height: 0;
                }
                30% {
                    height: $size - $sc;
                }
				61%{
					top: 0;
					height: $size - $sc;
				}
				90%{
					top: $size - $sc;
					height: 0;
				}
				100%{
					top: $size - $sc;
					height: 0;
				}
            }
		}
    }
}